<template>
  <div>
    <h3>Zahlungspläne Premium (Bitte anklicken):</h3>
    <ul v-if="!premium">
      <li>
        <a
          :href="getIframe(0)"
          target="_blank"
          class="flix-html-a flix-text-success"
          >Bookingflix Premium (monatliche Lastschrift-Abbuchung)</a
        >
      </li>
      <li>
        <a
          :href="getIframe(1)"
          target="_blank"
          class="flix-html-a flix-text-success"
          >Bookingflix Premium (jährliche Lastschrift-Abbuchung)</a
        >
      </li>
      <li>
        <a
          :href="getIframe(2)"
          target="_blank"
          class="flix-html-a flix-text-success"
          >1 weiterer Buchungs-Kalender (monatliche Lastschrift-Abbuchung)</a
        >
      </li>
      <!--<li>
        <a
          :href="getIframe(3)"
          target="_blank"
          class="flix-html-a flix-text-success"
          >1 weiterer Buchungs-Kalender (jährliche Lastschrift-Abbuchung)</a
        >
      </li> -->
      <li>
        <a
          :href="getIframe(4)"
          target="_blank"
          class="flix-html-a flix-text-success"
          >2 weitere Buchungs-Kalender (monatliche Lastschrift-Abbuchung)</a
        >
      </li>
      <!-- <li>
        <a
          :href="getIframe(5)"
          target="_blank"
          class="flix-html-a flix-text-success"
          >2 weitere Buchungs-Kalender (jährliche Lastschrift-Abbuchung)</a
        >
      </li>-->
    </ul>

    <h3>
      Alternative Zahlung per Überweisung (bitte nur bei jährlicher Abrechnung):
    </h3>
    Sie können auch per Rechnungskauf den Betrag bezahlen und den
    Premium-Account verlängern. Bitte schreiben Sie uns per Email (<a
      href="mailto:support@bookingflix.com"
      class="flix-html-a"
      >support@bookingflix.com</a
    >) an, wenn Sie eine Zahlung auf Rechnung bevorzugen (nur jährliche
    Abrechnung bei Kauf auf Rechnung möglich).
    <p>
      Drei Buchungskalender sind in Premium bereits inklusive. Wenn Sie also
      einen vierten oder fünften Kalender nutzen möchten, bitte diese über die
      Zahlungspläne bestellen.
      <b>Beispiel</b>: Wenn Sie den Premium-Account mit vier Kalendern
      benötigen, bitte den Lastschrift-Plan Premium und den Plan für einen
      weiteren Kalender separat bestellen.
    </p>
    <p>
      Bitte kontaktieren Sie uns, falls Sie weitere Buchungskalender benötigen.
      Diese können wir jederzeit kurzfristig freischalten.
    </p>

    <h3>Hier alle Leistungen und Preise im Überblick:</h3>

    <ul>
      <li>
        <a
          target="_blank"
          class="flix-html-a"
          href="https://bookingflix.de/features"
          >bookingflix.de/features</a
        >
      </li>
      <li>
        <a
          target="_blank"
          class="flix-html-a"
          href="https://bookingflix.de/prices"
          >bookingflix.de/prices</a
        >
      </li>
    </ul>
    <p>
      Der Premium-Account, den Sie derzeit nutzen, kann gerne gebührenpflichtig
      verlängert werden. Dafür haben Sie einen täglichen, persönlichen Support,
      Terminbuchungen ohne Limit, drei Kalender inklusive, Backup-Support
      just-in-time. Auch nach der Pandemie wird Bookingflix Sie im Hinblick auf
      Termin-Shopping, Buchung von Beratungs-Terminen und weiteren
      Zukunfts-Tools für den Einzelhandel und den Dienstleistungsbereich
      unterstützen. Diese Leistungen werden wir im Premium-Account bündeln.
    </p>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      premium: false,
      url: 'https://pay.gocardless.com/',
      plans: [
        'AL00153SRAA7GA' /* Premium 1 month */,
        'AL00153SRC3ZNV' /* Premium 1 year */,
        'AL00153SRF1AM7' /* 1 calendar month */,
        'AL0003N85424ZT' /* 1 calendar year */,
        'AL00153SRK3VNX' /* 2 calendar month */,
        'AL0003N855PSWR' /* 2 calendar year */
      ]
    }
  },
  computed: {},
  mounted() {},
  methods: {
    setPremium(p) {
      this.premium = p
    },
    getIframe(p) {
      return this.url + this.plans[p]
    }
  }
}
</script>
<style lang="sass" scoped></style>
